@import (reference) "_helpers-forex.less";
@import (reference) "_breakpoint.less";

.main-forex {
	&-header-slider {
		background-image: url(/i/forex/f-bg-main-header-slider.svg);
		background-repeat: no-repeat;
		background-position: ~"calc(50% + 260px)" center;

		.md({
			padding-bottom: 70px;
			background-size: 1200px auto;
			background-position: center 45px;
		});

		.VueCarousel {
			flex-direction: row;

			.md({
				flex-direction: column;
			});

			&-pagination {
				width: 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.md({
					flex-direction: row;
					width: auto;
				});
			}

			&-dot-container {
				.md({
					line-height: 20px;
				});
			}

			&-dot {
				opacity: 0.4;
				background-color: @forex-color-dark !important;
				width: 6px !important;
				height: 6px !important;

				.md({
					vertical-align: middle;
					padding: 7px !important;
				});

				&--active {
					opacity: 1;
					width: 8px !important;
					height: 8px !important;
				}
			}
		}

		.lg-mf({
			.carousel {
				display: flex;
				align-items: center;

				.carousel__pagination {
					flex-direction: column;
				}
			}
		});

		&__slide {
			padding-right: 50%;
			min-height: 540px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-repeat: no-repeat;
			text-align: left;

			.md({
				padding-right: 0;
				padding-top: 280px;
				min-height: 0;
				text-align: center;
			});

			&_type {
				&_1 {
					background-image: url(/i/forex/f-main-slide-1.svg);
					background-position: ~"calc(50% + 250px)" ~"calc(50% - 70px)";

					.md({
						background-size: 280px;
						background-position: center 30px;
					});
				}

				&_2 {
					background-image: url(/i/forex/f-main-slide-2.svg);
					background-position: ~"calc(50% + 265px)" ~"calc(50% - 70px)";

					.md({
						background-size: 280px;
						background-position: center 30px;
					});
				}
			}
		}

		&__title {
			font-weight: 700;
			font-size: 56px;
			line-height: 72px;
			margin-bottom: 15px;

			.md({
				font-size: 32px;
				line-height: 42px;
			});
		}

		&__desc {
			font-weight: 400;
			font-size: 18px;
			line-height: 30px;
			opacity: 0.8;
			margin-bottom: 30px;

			.md({
				font-size: 16px;
				line-height: 28px;
			});
		}

		&__buttons {
			.f-btn {
				margin-right: 20px;

				.md({
					margin-right: 0;
					margin-bottom: 20px;
					width: 100%;
				});
			}
		}

		&__loader {
			min-height: 540px;
			display: flex;
			justify-content: center;
			align-items: center;

			.md({
				min-height: 200px;
			});
		}
	}

	&-advan {
		&__wrapper {
			margin: -40px auto 0 auto;
			overflow-x: auto;
			max-width: 1172px;
			padding-bottom: 20px;

			&-inner {
				display: flex;
				width: 1172px;
				padding: 0 30px;

				.md({
					padding: 0 54px;
					width: 1222px;
				});
			}
		}

		&__item {
			width: 260px;
			height: 230px;
			padding-top: 135px;
			text-align: center;
			background-repeat: no-repeat;
			background-position: center 55px;
			font-weight: 500;
			font-size: 18px;
			line-height: 28px;

			margin-right: 26px;
			&:last-child { margin-right: 0; }

			flex-grow: 0;
			flex-shrink: 0; 

			&_bg {
				&_1 { background-image: url(/i/forex/f-icon-advan-1.svg); }
				&_2 { background-image: url(/i/forex/f-icon-advan-2.svg); }
				&_3 { background-image: url(/i/forex/f-icon-advan-3.svg); }
				&_4 { background-image: url(/i/forex/f-icon-advan-4.svg); background-position: center 47px;}
			}

			&-title {
				max-width: 180px;
				margin: 0 auto;
			}
		}
	}

	&__payment-block {
		padding: 120px 40px 40px 40px;
		text-align: center;
		background-repeat: no-repeat;
		background-position: center 40px;
		background-size: auto 60px;
		margin-bottom: 30px;

		&_type {
			// &_card { background-image: url(/i/forex/paymethod/f-icon-card.svg); }
			&_perfectmoney { background-image: url(/i/forex/paymethod/f-icon-perfectmoney.svg); }
			&_advcash { background-image: url(/i/forex/paymethod/f-icon-advcash.svg); }
			&_switchere { background-image: url(/i/forex/paymethod/f-icon-switchere.svg); }
			&_switcherecard { background-image: url(/i/forex/paymethod/f-icon-switcherecard.svg); }
			&_paymentasia_myr { background-image: url(/i/forex/paymethod/f-icon-paymentasia_myr.svg); }
			&_paymentasia_vnd { background-image: url(/i/forex/paymethod/f-icon-paymentasia_vnd.svg); }
			&_paymentasia_thb { background-image: url(/i/forex/paymethod/f-icon-paymentasia_thb.svg); }
			&_paymentasia_idr { background-image: url(/i/forex/paymethod/f-icon-paymentasia_idr.svg); }
			&_indonesialocal { background-image: url(/i/forex/paymethod/f-icon-indonesialocal.svg); }
		}

		&-title {
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 13px;
		}

		&-desc {
			font-size: 14px;
			line-height: 24px;
			opacity: 0.7;
		}

		&-wrapper {
			margin-top: 50px;

			.md({
				margin-top: 25px;
			});
		}
	}

	&__tabs {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;

		.nav-link {
			position: relative;
			font-weight: 700;
			font-size: 24px;
			line-height: 29px;
			background: none;
			opacity: 0.4;
			padding: 15px 30px;
			transition: all 0.5s;

			.md({
				font-size: 12px;
				line-height: 16px;
				font-weight: 400;
				max-width: 88px;
				height: 60px;
				padding: 15px 10px;
				text-align: center;
			});
			
			&, &:active, &:focus, &:hover, &.active {
				color: @forex-color-dark;
			}

			&.active {
				background: none;
				opacity: 1;
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 0px;
				height: 5px;
				background: @forex-color-green;
				border-radius: 4px;
				bottom: 0;
				left: 50%;
				transform: translate(-50%);
				transition: all 0.5s;
			}

			&.active::before {
				width: 60px;
			}
		}
	}
}
